import React, { useEffect, useContext, useState, useRef } from 'react';
import { Icon,  Button, Modal, Grid, Header, Image, Form, Input } from 'semantic-ui-react'

import LogoTeamLigaGenerator from '../../../../../assets/logotgver1.svg';
import AuthContext from '../../../../../context/authContext';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { ConnectWS } from '../../../../../ConnectWS';
import { useToasts } from 'react-toast-notifications';
import { AG_GRID_LOCALE_PL } from '../../../../../LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import AGGridActivity from '../../../../../AGGridActivity';

//import Voucher from './Voucher';

function JudgesList({ addJudges, selectionType }) {
  const { addToast } = useToasts();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
  const authContext = useContext(AuthContext);// eslint-disable-line no-unused-vars
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(null)

  const [judgesList, setjudgesList] = useState([])

  const onGridReady = (params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)

  };

  const [formAddJ, setFormAddJ] = useState({
    name: "",
    surname: "",
    email: "",
    additional_info: ""
  })

  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyAccount")
    }
    getJudges()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps



  const submitModalAddJ = e => {
    e.preventDefault();
    //generator/addArbiterToSubtournament -> subtournament_id, person_name, person_email, description
    //generator/removeArbiterFromSubtournament -> subtournament_id, person_id
  }



  function getJudges() {
    ConnectWS("/generator/getAllMembers", authContext.token, { subtournament_id: null }, function (response, logout) {

      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {

        setjudgesList(response.data)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });

  }

  // zaznaczenie rekordu w tab
  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();

    if (selectedRows.length >= 1) {

      console.log(selectedRows)
    }
    else {

    }

  };

  const onCellValueChanged = (data) => {
    console.log(data)
  }

  return (
    <>
      {addJudges ?
        <Button onClick={() => setOpen(true)} color='orange'>
          <Icon name="add" /> DODAJ SĘDZIEGO
        </Button>
        : ""}
      <AGGridActivity quickSearchHidden={false} gridApi={gridApi} gridColumnApi={gridColumnApi} gridApiRef={gridRef} />
      <div className="ag-theme-balham" style={{ position: "relative", marginTop: 10, height: height < 900 ? height - 80 : height - 320, width: "100%" }}>


        <AgGridReact
          ref={gridRef}
          onSelectionChanged={onSelectionChanged}
          onCellValueChanged={onCellValueChanged}
          rowSelection={selectionType > 1 ? 'multiple' : 'single'}
          animateRows={true}
          defaultColDef={{
            editable: false,
            sortable: true,
            minWidth: 100,
            filter: false,
            resizable: true,
            floatingFilter: false,
            flex: 1,
          }}
          localeText={AG_GRID_LOCALE_PL}
          onGridReady={onGridReady}
          rowData={judgesList}
        >
          <AgGridColumn checkboxSelection={selectionType > 0 ? true : false} field="member_name" headerName="Nazwa" filter="agMultiColumnFilter" />
          <AgGridColumn field="member_email" editable={true} headerName="Email" filter="agMultiColumnFilter" />
          <AgGridColumn field="member_ss" headerName="Dodatkowe informacje" filter="agMultiColumnFilter" />
        </AgGridReact>
      </div>


      {addJudges ?
        <Modal
          open={open ? true : false}
          centered={width < 900 ? false : true}
        >
          <Modal.Header>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={12} verticalAlign="middle">
                  <Header as='h2' textAlign='center'>
                    <Icon name='add' color="red" />
                    <Header.Content>Dodaj sędziego</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right" only='large screen'>
                  <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Header>
          <Modal.Content className='CardBlack'>

            <Modal.Description>
              {open ? <>
                <Form onSubmit={submitModalAddJ} size='tiny'>
                  <Form.Field
                    control={Input}
                    required
                    label='Imię i Nazwisko'
                    placeholder='Imię i Nazwisko'
                    value={formAddJ.name}
                    onChange={(e, data) => setFormAddJ({ ...formAddJ, name: data.value })}
                  />
                  <Form.Field
                    control={Input}

                    type='email'
                    label='Email'
                    placeholder='Email'
                    value={formAddJ.email}
                    onChange={(e, data) => setFormAddJ({ ...formAddJ, email: data.value })}
                  />
                  <Form.Field
                    control={Input}
                    label='Dodatkowe informacje'
                    placeholder='Dodatkowe informacje'
                    value={formAddJ.additional_info}
                    onChange={(e, data) => setFormAddJ({ ...formAddJ, additional_info: data.value })}
                  />
                  <Button fluid color='blue'>DODAJ SĘDZIEGO</Button>
                </Form>
              </> : ""}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={() => {
              setOpen(null)
            }}>
              ZAMKNIJ
            </Button>
          </Modal.Actions>
        </Modal>
        : ""}
    </>
  )
}

export default JudgesList;